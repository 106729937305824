.my-editor {
    border: 1px solid #d1d1d1;
    border-radius: 5px;
}
.my-form{
    width: 100%;
    margin-bottom: 20px;
}

.my-form .select-box{
    width: 180px;
}