.avatar-box{
    position: relative;
    &:hover{
        .avatar-del{
            opacity: 1;
        }
    }
    .avatar-del{
        position: absolute;
        width: 104px;
        height: 104px;
        margin: 0 auto;
        left: 39%;
        top: 0;
        bottom: 0;
        font-size: 20px;
        opacity: 0;
        color: #fff;
        background-color: rgba(0, 0, 0, .3);
        z-index: 999;
        cursor: pointer;
    }
}