.row-item{
    text-align: center;
    line-height: 40px;
    border-top: 1px solid #000;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    :nth-child(1){
        border-right: 1px solid #000;
    }
}
.row-item-last{
    border-bottom: 1px solid #000;
}