.s1{
    width: 100%;
    height: 100%;
    line-height: 100%;
    text-align: right;
    padding-right: 10px;
}

.s2{
    text-align: center;
}
.s3{
    font-size: 12px;
    text-align: right;
    color: #1890ff;
}
.s-text-left{
  text-align: left;  
}
.s4{
    line-height: 40px;
}
.s5{
    /* text-align: right; */
}
input{
    border-radius: 4px;
}