.tag-box {
    position: relative;
    text-indent: 10px;
    font-size: 16px;
    height: 22px;
    line-height: 19px;
    color: #333333;
    margin-bottom: 22px;
}

.tag-box::before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 4px;
    height: 16px;
    left: 0;
    top: 2px;
    background: #4862EF;
}