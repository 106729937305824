.loginPage {
    height: 100vh;
    background:url('./../../assets/img/bg.png') no-repeat center top;
    background-size: cover;
}

.rowBox {
    width: 1056px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .rowBoxAll {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loginLeft,
    .loginRight {
        width: 50%;
        height: 500px;

        img {
            max-width: 100%;
        }
    }

    .loginRight {
        padding-top: 50px;
        text-align: left;
        border-radius: 8px;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        box-shadow: 0 4px 11px 0 rgba(215, 224, 232, 0.34);
        background-color: rgba(255, 255, 255, 0.8);

        .formBox {
            width: 300px;
            margin: 0 auto;

            .tag {
                position: relative;
                font-size: 24px;
                font-weight: 600;
                color: #2d4058;

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -20px;
                    width: 40px;
                    height: 1px;
                    border: solid 2px #dbdbdb;
                }
            }

            .formBox-box {
                padding-top: 30px;
            }
        }
    }
}

// .formBox{
//     width: 400px;
//     margin: 0 auto;
//     text-align: center;
//     .from{
//         justify-content: center;
//     }
// }