.box {
  position: relative;
  padding: 38px 20px;
}
.box::before{
  content: ''; position: absolute;top: 0;left: 0;right: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  border-radius: 8px 8px 0px 0px;
}

.box .boxImgBox{
  width: 40px;
  height: 40px;
  padding: '8px 16px 16px 12px';
  background-size: contain;
}

.box:nth-child(1)::before{
  background-color: #F7CEA3;
}
.box:nth-child(1) .boxImgBox{
  background-image: url('./logo1.png');
}

.box:nth-child(2)::before{
  background-color: #AFC3FF;
}
.box:nth-child(2) .boxImgBox{
  background-image: url('./logo2.png');
}

.box:nth-child(3)::before{
  background-color: #8BD3EE;
}
.box:nth-child(3) .boxImgBox{
  background-image: url('./logo3.png');
}

.totalBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 200px;
  padding-left: 10%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center top;
}

.title {
  font-size: 16px;font-weight: 600;
  line-height: 22px;
  color: #677392;
}
.mainTitle{
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  color: #253468;
}