body,html{
  ::-webkit-scrollbar{
    width: 0;
    display: none;
  }
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-box{
  .ant-modal-header{
    text-align: center;
    background-color: #4862ee;
    .ant-modal-title{
      color: #fff;
    }
  }
  .ant-modal-close{
    color: #fff;
  }
  img{
    max-width: 100%;
  }
}


#components-layout-demo-custom-trigger .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }
  
  #components-layout-demo-custom-trigger .logo {
    margin: 16px auto;
    img{
      width: 20px;
      height: 24px;
      object-fit: contain;
    }
    span{
      color: #fff;
    }
  }
  
  .site-layout .site-layout-background {
    background: #fff;
  }

  .site-layout .ant-layout-header{
    text-align: left;
  }

  .header-box{
    border: solid 1px #e5e5e5;
  }

  .header-top{
    display: flex;
    justify-content: space-between;
  }
  .refesh-icon-box{
    margin-right: 50px;
  }
  .top-user-info-box{
    &:hover{
      background-color: #f9f9fa;
    }
  }
  .user-headImg-box{
    padding-right: 40px;
    img{
      width: 26px;
      height: 26px;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .header-tag{
    width: 100%;
    padding: 8px 24px;
  }
  .current-title{
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    font-weight: 600;
    color: #333;
  }

  .center-box{
    min-height: calc(100vh - 112px);
  }